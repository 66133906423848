import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { Theme } from 'styles/theme';

export const Wrapper = styled.ul`
  display: flex;
  flex-flow: row wrap;

  @media (${breakpoint.lgMin}) {
    flex-flow: row nowrap;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125))
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    flex-flow: row nowrap;
    gap: 16px;
  }

  &.hidden {
    display: none;
  }
  li {
    flex: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-top: 24px;

    @media (${breakpoint.mdMin}) {
      margin-right: 40px;
      margin-left: 0;
      flex: 45%;
      padding-top: 24px;
      padding-bottom: 0px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
    and (${breakpoint.mdMin}) 
    and (${breakpoint.lgMax}) {
      margin-right: 0px;
      padding-top: 0;
      padding-bottom: 0;
    }

    @media (${breakpoint.lgMin}) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  li:first-child {
    padding-top: 24px;
    @media (${breakpoint.lgMin}) {
      padding-top: 0;
    }
    @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
    and (${breakpoint.mdMin}) 
    and (${breakpoint.lgMax}) {
      padding-top: 0;
    }
  }

  &.one, &.two {
    li {
      @media (${breakpoint.lgMin}) {
        flex: 0 1 calc(25% - 40px/2);
        width: calc(25% - 40px/2);
      }
    }
  }
  &.three {
    li {
      @media (${breakpoint.mdMin}) {
        flex: 0 1 calc(33.3% - 80px/3 - 5px);
        max-width: calc(33.3% - 40px/3 - 5px);
        flex-grow: 1;
      }
    }
  }
  &.four {
    li:nth-of-type(even) {
      @media (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
        margin-right: 0;
      }
    }
  }
  &.more {
    flex-wrap: wrap;

    li {
      @media (${breakpoint.mdMin}) {
        flex: 0 1 calc(50% - 40px/2);
        width: calc(50% - 40px/2);
      }
    }

    li:nth-of-type(even) {
      @media (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
        margin-right: 0;
      }
    }

    @media (${breakpoint.lgMin}) {
      row-gap: 40px;

      li {
        flex: 0 1 calc(33.3% - 80px/3 - 5px);
        width: calc(33.3% - 80px/3 - 5px);
      }

      li:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }

  &.col-4 {
    gap: 16px 24px;
    flex-wrap: wrap;
    @media (${breakpoint.xlMin}) {
      gap: 24px 48px;
    }
    li {
      margin: 0 !important;
      padding: 0 !important;
      flex: 100%;
      @media (${breakpoint.mdMin}) {
        flex: 0 0 calc(50% - 24px/2);
      }
      @media (${breakpoint.lgMin}) {
        flex: 0 0 calc(25% - 48px*3/4);
      }
    }
  }
`;

export const SliderWrapper = styled.div`
  .slick-slider {
    transition: padding 0.1s ease-in-out;
  }

  &.next {
    .slick-slider {
      padding-left: 12px;
    }
  }

  &.prev {
    .slick-slider {
      padding-right: 12px;
    }
  }

  div.slick-slide {
    padding: 0 12px;
  }

  margin-left: -24px;
  margin-right: -24px;
`;

export const DesktopSliderWrapper = styled.div`
  div.slick-slide {
    padding: 0 12px;
    visibility: hidden;

    &.slick-active {
      visibility: visible;
    }
  }

  margin-left: -12px;
  margin-right: -12px;

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125)) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    margin-left: -40px;
    margin-right: -40px;
  }
`;

export const ArrowWrapper = styled.div<{ theme: Theme }>`
  top: 90px;
  ${getIcon('arrow-button-slick')}

  &.slick-next:before,
  &.slick-prev:before {
    display: none;
  }

  &.slick-next.slick-disabled,
  &.slick-prev.slick-disabled {
    visibility: hidden;
  }

  &.slick-next {
    right: -1px;
  }

  &.slick-prev {
    left: -1px;
  }

  @media (${breakpoint.lgMin}) {
    &.slick-next {
      right: 12px;
    }

    &.slick-prev {
      left: 12px;
    }
  }
`;
