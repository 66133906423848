import BylineList from 'components/Byline';
import Link from 'components/Globals/Base/Link';
import { NextCustomImage } from 'components/Image';
import { ArticlePreview } from 'interfaces/content/articles/Articles';
import { setEventAction } from 'utils/trackDataUtils';
import parseCaptionWhenAltTextEmpty from 'utils/parsingUtils/clientSideParsing';
import { getDevicePixelRatio } from 'use-device-pixel-ratio';
import { ARTICLE_CARD_TEST_IDS } from 'constants/testsIds/articleComponents';
import { FEATURED_ARTICLE_TEST_IDS, IMAGE_TEST_IDS, TEXT_TEST_IDS } from 'constants/testsIds/homepage';
import * as S from './FeaturedArticleStyles';

interface FeaturedArticleProps extends ArticlePreview {
  isFirstPrimary?: boolean;
}

const FeaturedArticle = ({
  eyebrowSectionLabel,
  eyebrowSectionLink,
  eyebrowTagLabel,
  title,
  titleLink,
  excerpt,
  authors,
  date,
  image,
  isFirstPrimary,
}: FeaturedArticleProps) => {
  const trackingData = {
    contentPlacementCD: 'hero',
    eventAction: setEventAction(eyebrowSectionLabel, eyebrowTagLabel),
    eventCategory: 'article',
    eventLabel: `title click : ${title}`,
  };
  const recommendsArticle = titleLink.match(/recommends/g);
  const dpr = getDevicePixelRatio({ round: false }) === 2.125 || getDevicePixelRatio({ round: false }) === 1.875;
  const fontSize = dpr ? 'level3' : 'level2';

  return (
    <S.Wrapper
      className={isFirstPrimary ? 'first-primary' : ''}
      data-cy={FEATURED_ARTICLE_TEST_IDS.FEATURED_ARTICLE}
    >
      <S.ContentWrapper>
        <S.ImageWrapper
          className='image'
          data-cy={IMAGE_TEST_IDS.IMAGE_WRAPPER}
        >
          <Link
            href={titleLink}
            trackingData={{ ...trackingData, eventLabel: `banner click : ${title}` }}
            ariaLabel={`Go to featured article ${title}`}
            type='image'
            className='image-link'
            dataCy={ARTICLE_CARD_TEST_IDS.IMAGE_LINK}
          >
            <NextCustomImage
              src={image.mediaItemUrl}
              alt={parseCaptionWhenAltTextEmpty(image)}
              width='720'
              height='480'
              priority={isFirstPrimary}
              data-cy={IMAGE_TEST_IDS.IMAGE}
            />
          </Link>
        </S.ImageWrapper>
        <S.TextWrapper
          className='text'
          data-cy={TEXT_TEST_IDS.TEXT}
        >
          {eyebrowSectionLabel !== 'No Primary Section Found' && (
            <S.Section>
              <Link
                href={eyebrowSectionLink}
                trackingData={{ ...trackingData, eventLabel: `category click : ${title}` }}
                ariaLabel={`Go to featured article's section ${eyebrowSectionLabel}`}
                type='red'
                dataCy={ARTICLE_CARD_TEST_IDS.EYEBROW}
              >
                {eyebrowSectionLabel}
              </Link>
            </S.Section>
          )}
          <S.Title
            as='h2'
            $fontFamily='saol'
            $size={{ default: 'level3', lgMin: 'level1', mdMin: fontSize }}
            data-cy={TEXT_TEST_IDS.HEADING_H2}
          >
            <Link
              href={titleLink}
              trackingData={trackingData}
              ariaLabel={`Read the featured article titled ${title}`}
              type='primary'
              className='title-link'
              dataCy={ARTICLE_CARD_TEST_IDS.TITLE}
            >
              {title}
            </Link>
          </S.Title>
          <S.Excerpt>
            <Link
              href={titleLink}
              trackingData={trackingData}
              ariaLabel={`Read the full article titled ${title}`}
              type='primary'
              dataCy={ARTICLE_CARD_TEST_IDS.EXCERPT}
            >
              {excerpt}
            </Link>
          </S.Excerpt>
          {authors.length !== 0 && (
            <BylineList
              bylineList={authors}
              trackingData={{ ...trackingData, eventLabel: title }}
            />
          )}
          {!recommendsArticle && <S.Publishdate data-cy={ARTICLE_CARD_TEST_IDS.DATE}>{date}</S.Publishdate>}
        </S.TextWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default FeaturedArticle;
